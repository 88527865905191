import React from 'react'
import MetaTags from 'react-meta-tags';

const Impressum = () => (
    <div className="container mx-auto">
        <MetaTags>
            <title>Impressum | WÖS.CH</title>
            <meta name="description" content="Auf dieser Seite findest Du unser Impressum." />
            <meta name="image" content="https://www.wös.ch/images/logo.png" />
            <meta itemProp="name" content="Unsere Datenschutzbestimmungen | WÖS.CH" />
            <meta itemProp="description" content="Auf dieser Seite findest Du unser Impressum." />
            <meta itemProp="image" content="https://www.wös.ch/images/logo.png" />
            <meta name="og:title" content="Unsere Datenschutzbestimmungen | WÖS.CH" />
            <meta name="og:description" content="Auf dieser Seite findest Du unser Impressum." />
            <meta name="og:image" content="https://www.wös.ch/images/logo.png" />
            <meta name="og:url" content="https://www.wös.ch/imprint" />
            <meta name="og:site_name" content="wös.ch" />
            <meta name="og:locale" content="de_CH" />
            <meta name="og:type" content="website" />
        </MetaTags>
        <div data-packed="false" data-min-height="91"
            style={{
                minHeight: "91px",
                marginTop: "85px",
                marginBottom: "0px",
                verticalAlign: "bottom"
            }}>
            <h1 className="font_3" style={{ textAlign: "center", marginBottom: "0px", marginTop: "0px", verticalAlign: "bottom", color: "rgb(0, 0, 0)" }}>
                Impressum
            </h1>
        </div>
        <div data-packed="false" data-min-height="392" className="mx-auto"
            style={{
                maxWidth: "747px",
                minHeight: "392px",
                marginTop: "0px"
            }} >
            <p className="font_8" style={{
                color: "#000000",
                fontSize: "20px",
                textAlign: "center",
                fontFamily: "Brendon-Grotseque-light"
            }}>
                Verantwortlich für diese Seite
            </p>

            <p className="font_8" style={{
                color: "#000000",
                textAlign: "center",
                fontFamily: "Brendon-Grotseque-light"
            }}>
                wös.ch GmbH<br/>
                Hohlstrasse 192<br/>
                8004 Zürich<br/>
                <br/>
                044 304 22 98<br/>
                info@woes.ch<br/>
                CH-020.4.066.730-6<br/>
            </p>

        </div >
    </div >
)

export default Impressum