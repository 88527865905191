import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

class Footer extends Component {

    render() {
        return (
            <footer className="main-footer">
                <div className="main-block">
                    <div className="container pt-3 pb-3">
                        <Row className="justify-content-md-center">
                            <Col sm="4">
                                <Row className="align-items-center">
                                    <Col md="auto">
                                        <img style={{ height: '120px', width: '120px' }}
                                            src={require("../img/logo_white.png")}
                                            alt="..."
                                        />
                                    </Col>
                                    <Col className="align-self-center" md="auto" style={{ fontSize: "15px", color: "white" }}>
                                        <Row><Col>Hohlstrasse 192</Col></Row>
                                        <Row><Col>8004 Zürich</Col></Row>
                                        <Row><Col>044 304 22 98</Col></Row>
                                        <Row><Col>info@woes.ch</Col></Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="align-self-center" sm="2" style={{ fontSize: "15px", color: "white" }}>
                                <Row>
                                    <Link to="/agb" style={{ color: "white" }}>AGB</Link>
                                </Row>
                                <Row>
                                    <Link to="/privacy" style={{ color: "white" }}>Datenschutz</Link>
                                </Row>
                                <Row>
                                    <Link to="/imprint" style={{ color: "white" }}>Impressum</Link>
                                </Row>
                                <Row className="Link2">
                                    <Link to="/faq" style={{ color: "white" }}>FAQ</Link>
                                </Row>
                            </Col>
                            <Col className="align-self-center" sm="4" style={{ fontSize: "15px", color: "white" }}>
                                <Row><Col>Öffnungszeiten</Col></Row>
                                <Row><Col>Montag — Freitag</Col><Col>06.00 — 18.00</Col></Row>
                                <Row><Col>Samstag</Col><Col>09.00 — 15.00</Col></Row>
                                <Row><Col>www.wös.ch</Col><Col>24/7</Col></Row>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center" style={{ marginTop: "40px" }}>
                            <img style={{ width: '70px', height: "50px", padding: "10px" }}
                                src={require("../img/payment_methods/mastercard.png")}
                                alt="mastercard"
                            />
                            <img style={{ width: '70px', height: "50px", padding: "10px" }}
                                src={require("../img/payment_methods/visa.png")}
                                alt="visa"
                            />
                            <img style={{ width: '70px', height: "50px", padding: "10px" }}
                                src={require("../img/payment_methods/postfinance.png")}
                                alt="postfinance"
                            />
                            <img style={{ width: '70px', height: "50px", padding: "10px" }}
                                src={require("../img/payment_methods/twint.png")}
                                alt="twint"
                            />
                            <img style={{ width: '70px', height: "50px", padding: "10px" }}
                                src={require("../img/payment_methods/paypal.png")}
                                alt="paypal"
                            />
                            <img style={{ width: '70px', height: "50px", padding: "10px" }}
                                src={require("../img/payment_methods/rechnung.png")}
                                alt="rechnung"
                            />
                            <img style={{ width: '70px', height: "50px", padding: "10px" }}
                                src={require("../img/payment_methods/prepaid_card.png")}
                                alt="prepaid_card"
                            />
                            <img style={{ width: '70px', height: "50px", padding: "10px" }}
                                src={require("../img/payment_methods/gift_card.png")}
                                alt="gift_card"
                            />
                        </Row>
                    </div>
                </div>
            </footer>

        );
    }
}
export default Footer;