import React, { Component, useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { getItemsCount } from '../store/reducers/shoppingCart';
import { getUsername } from '../store/reducers/auth';
import { formatNumber } from '../Utils'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';


import * as actions from '../store/actions'

class Header extends Component {

    state = {
        dropdownOpen: false,
        isScrolled: false,
        currentPath: window.location.pathname.replace(/\//g, '-')
    };


    componentDidMount() {
        document.querySelector('.content').addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            const currentPath = window.location.pathname.replace(/\//g, '-');
            this.setState({ currentPath });
        }
    }

    componentWillUnmount() {
        document.querySelector('.content').removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        this.setState({ isScrolled: document.querySelector('.content').scrollTop > 150 });
    };

    render() {

        const { totalPrice, itemsCount, userName, OnAuthModalToggle, OnUserLogout, isAdmin } = this.props
        const { currentPath } = this.state;


        return (
            <React.Fragment>
                <header className={`App-header page-${currentPath}`}>
                    <nav className={`navbar navbar-expand-xl ${this.state.isScrolled ? 'scrolled' : ''}`}>
                        <div className="container-fluid">
                            <button
                                type="button"
                                aria-controls="navbarCollapse"
                                aria-expanded="false"
                                className="navbar-toggler navbar-toggler-right"
                                onClick={() => { this.props.toggleSideBar() }}
                            >
                                <i className="fa fa-bars" />
                            </button>
                            <NavLink to="/" className="navbar-brand">
                                        <img style={{ height: '34px' }} src={require("../img/wos_logo.png")} alt="Home" />
                                    </NavLink>
                            <div id="navbarCollapse" className="collapse navbar-collapse">
                                <ul className="navbar-nav navbar-margin" style={{ whiteSpace: "nowrap" }}>
                                    
                                    {/* <li className="nav-item">
                                        <NavLink to="/" className="nav-link" activeClassName="active" exact>Home</NavLink>
                                    </li> */}
                                    <li className="nav-item">
                                        <NavLink to="/how-it-works" className="nav-link" activeClassName="active">So geht's</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/services" className="nav-link" activeClassName="active">Service</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/price" className="nav-link" activeClassName="active">Preise</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/for-companies" className="nav-link" activeClassName="active">Für Firmen</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/eco-friendly" className="nav-link" activeClassName="active">Eco-Friendly</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/contact" className="nav-link" activeClassName="active">Kontakt</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="right-col d-flex align-items-center flex-row flex-lg-row ml-auto" style={{ whiteSpace: "nowrap" }}>
                                <NavLink to="/products" className="nav-link shopping-button" activeClassName="active" style={{ marginLeft: "0px" }}>
                                   <span className="text-small">Produkte</span><span>Wäschekorb füllen</span>
                                </NavLink>
                                <div className="user ml-auto">
                                    <a
                                        href="/#"
                                        className="user-link"
                                        alt="Dein Account"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            if (userName) {
                                                this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }))
                                            } else {
                                                OnAuthModalToggle()
                                            }
                                        }}>
                                        <div className="user-section">
                                            <i className="icon-profile"></i>
                                            {
                                            userName &&
                                                <span style={{ color: "white", fontFamily: "Brendon-Grotseque-regular", fontSize: "14px" }}>
                                                    <span className="desktop-text">{userName.split(' ')[0]}</span><span className="mobile-text">{Array.from(userName)[0] + '.'}</span>
                                                </span>
                                            }     
                                        </div>
                                       
                                    </a>
                                    <Dropdown isOpen={this.state.dropdownOpen}
                                        toggle={() => this.setState(prevState => ({
                                            dropdownOpen: !prevState.dropdownOpen
                                        }))}>
                                        <DropdownToggle caret style={{ display: "none" }} />
                                        <DropdownMenu className="user-login-menu">
                                            <DropdownItem tag={Link} to="/profile">Siehe Profil</DropdownItem>
                                            {isAdmin && <DropdownItem tag={Link} to="/admin/orders">Admin - Orders</DropdownItem>}
                                            {isAdmin && <DropdownItem tag={Link} to="/admin/users">Admin - Users</DropdownItem>}
                                            <DropdownItem tag={Link} to="#" onClick={() => OnUserLogout()}>Logout</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                                <div className="cart dropdown show">
                                    <Link
                                        to="/shopping-cart/page1"
                                        className="dropdown-toggle d-flex"
                                        alt="Wäschekorb"
                                    >
                                        <i className="icon-cart">
                                        </i>
                                        {
                                            itemsCount > 0 &&
                                            <div className="cart-no">{itemsCount}</div>
                                        }
                                        <span className="price-wrapper">
                                        <span style={{ color: "white", fontFamily: "Brendon-Grotseque-regular", fontSize: "14px" }}>
                                            {formatNumber(totalPrice)}
                                        </span>
                                        <span style={{ color: "white", fontFamily: "Brendon-Grotseque-regular", fontSize: "14px" }} className="ml-1">&#67;&#72;&#70;</span>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        OnAuthModalToggle: () => dispatch(actions.authModalToggle()),
        OnUserLogout: () => dispatch(actions.logout()),
        toggleSideBar: () => dispatch(actions.toggleSideBarModal())
    };
};

const mapStateToProps = state => ({
    isAdmin: state.auth.isAdmin,
    userName: getUsername(state),
    itemsCount: getItemsCount(state),
    totalPrice: state.shoppingCart.totalPrice
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));