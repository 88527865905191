import * as actionTypes from '../actions/actionTypes'
import _ from 'lodash';

const initialState = () => {

    const stateFromLocalStorage = localStorage.getItem("shoppingCart")

    if (stateFromLocalStorage) {

        const localState = JSON.parse(stateFromLocalStorage)

        return {
            ...localState,
            pickupLocationConfirmed: false,
            deliveryLocationConfirmed: false,
            selfPickUp: false,
            selfDelivery: false,
            deliveryDatesAvailable: deliveryDatesAvailable(localState.items),
            numberOfDeliveries: 1,
            giftcard: null,
            codeType: null,
            codeValue: null,
            welcomecode: null,
            codeUsed: false,
            orderId: null,
            paymentLink: null,
        }
    }

    return {
        itemsCount: 0,
        items: [],
        itemsPrice: 0,
        selfPickUp: false,
        selfDelivery: false,
        pickupLocationConfirmed: false,
        deliveryLocationConfirmed: false,
        bonus: 0,
        totalPrice: 0,
        shippingPrice: 0,
        deliveryDatesAvailable: [],
        giftcard: null,
        codeType: null,
        codeValue: null,
        welcomecode: null,
        codeUsed: false,
        orderId: null,
        paymentLink: null,
    }
};

const removeAllProductsFromShoppongCart = (state) => {
    return ({
        ...state,
        itemsCount: 0,
        items: [],
        itemsPrice: 0,
        selfPickUp: false,
        selfDelivery: false,
        pickupLocationConfirmed: false,
        deliveryLocationConfirmed: false,
        bonus: 0,
        totalPrice: 0,
        shippingPrice: 0,
        deliveryDatesAvailable: [],
        giftcard: null,
        codeType: null,
        codeValue: null,
        welcomecode: null,
        codeUsed: false
    })
}

const calculateItemsPrice = (items) => {
    let itemsPrice = 0

    items.forEach(item => {
        itemsPrice += item.sumPrice
    });

    return itemsPrice
}

const calculateTotalPrice = ({ itemsPrice, shippingPrice, codeType, codeValue }) => {
    switch (codeType) {
        case "welcomecode":
            if ((itemsPrice + shippingPrice) > 30) {

                if(window._paq) window._paq.push(['trackEcommerceCartUpdate', (itemsPrice + shippingPrice) - codeValue]); 

                return { totalPrice: (itemsPrice + shippingPrice) - codeValue, bonus: codeValue }
            }
            break;

        case "giftcard":
            if ((itemsPrice + shippingPrice) >= codeValue) {

                if(window._paq) window._paq.push(['trackEcommerceCartUpdate', (itemsPrice + shippingPrice) - codeValue]); 

                return { totalPrice: (itemsPrice + shippingPrice) - codeValue, bonus: codeValue }
            } else
                return { totalPrice: 0, bonus: (itemsPrice + shippingPrice) }
    }

    if(window._paq) window._paq.push(['trackEcommerceCartUpdate', itemsPrice + shippingPrice]); 


    return { totalPrice: itemsPrice + shippingPrice, bonus: 0 }
}

const calculateItemPriceInitialAdd = (product) => {
    if (product.laundry) {
        if (product.laundry.perKilo) {
            if (product.laundry.washingPerKiloPrice > 0) {
                product.itemPrice = product.laundry.washingPerKiloPrice
                product.sumPrice = product.laundry.washingPerKiloPrice
            } else {
                product.itemPrice = product.laundry.washingAndIroningPerKiloPrice
                product.sumPrice = product.laundry.washingAndIroningPerKiloPrice
            }
        } else {
            product.laundry.washingAndIroningSelected = true
            product.itemPrice = product.laundry.washingAndIroningPrice
            product.sumPrice = product.laundry.washingAndIroningPrice
        }
    } else if (product.leather) {
        if ((product.leather.velourPrice || 0) > 0) {
            product.leather.velourSelected = true
            product.itemPrice = product.leather.velourPrice
            product.sumPrice = product.leather.velourPrice
        } else if ((product.leather.napaPrice || 0) > 0) {
            product.leather.napaSelected = true
            product.itemPrice = product.leather.napaPrice
            product.sumPrice = product.leather.napaPrice
        }
    }
    if (product.delivery) {
        if (product.delivery.packed && product.delivery.packedPrice === 0) {
            product.delivery.packedSelected = true
        } else if (product.delivery.hanger && product.delivery.hangerPrice === 0) {
            product.delivery.hangerSelected = true
        }
    }
    if (product.giftCard) {
        product.itemPrice = product.giftCard.price
        product.sumPrice = product.giftCard.price
        product.giftCart = 'Als Geschenk für Dich "Ein Moment der Entspannung". Geniesse Deine Freizeit!'
    }
    product.itemQuantity = 1

    if(window._paq) window._paq.push(['addEcommerceItem',
        product._id, // (Required) productSKU
        product.name, // (Optional) productName
        product.category.name, // (Optional) productCategory
        product.sumPrice, // (Recommended) price
        1 // (Optional, defaults to 1) quantity
    ]);
}

const deliveryDatesAvailable = items => {

    if (items.every(item => item.longProcessing))
        return [14]

    if (items.every(item => item.longProcessing === undefined && (item.laundry ? (item.laundry.washingAndIroningSpeedySelected !== true) : false)))
        return [2]

    if (items.every(item => item.longProcessing === undefined && (item.laundry ? item.laundry.washingAndIroningSpeedySelected === true : false)))
        return [1]

    if (items.every(item => item.longProcessing === undefined && (item.laundry ? (item.laundry.washingAndIroningSpeedySelected === true) : false)))
        return [2]

    if (items.some(item => item.laundry ? (item.laundry.washingAndIroningSpeedySelected === true) : false) && items.some(item => item.longProcessing))
        return [1, 14]

    if (items.some(item => item.laundry ? (item.laundry.washingAndIroningSpeedySelected !== true) : false) && items.some(item => item.longProcessing))
        return [2, 14]

    return [1]
}

const addProductToShoppongCart = (state, { product }) => {

    let newItems = _.cloneDeep(state.items)
    let newProduct = _.cloneDeep(product)
    calculateItemPriceInitialAdd(newProduct)
    checkAndTurnOffSpeedyOptionForOldItems(newItems, product)
    checkAndTurnOnSpeedyOptionForNewItem(newItems, newProduct)
    newItems.push(newProduct)

    return returnStateAfterItemCheck({ state, newItems, itemsCount: state.itemsCount + 1 })
}

const checkAndTurnOnSpeedyOptionForNewItem = (items, product) => {
    if (items.length > 0 && items.every(item => item.laundry && item.laundry.washingAndIroningSpeedySelected === true)) {
        if (product.laundry && product.laundry.washingAndIroningSpeedy) {
            product.laundry.washingAndIroningSelected = false
            product.laundry.washingAndIroningSpeedySelected = true
            product.itemPrice = product.itemPrice + product.laundry.washingAndIroningSpeedyPrice
            product.sumPrice = product.itemPrice
        }
    }
}

const checkAndTurnOffSpeedyOptionForOldItems = (items, product) => {
    //Turn off speedy item if item added doesn't have speedy option
    if (product.longProcessing || (product.laundry ? product.laundry.washingAndIroningSpeedy === false : false)) {
        items.forEach(item => {
            if (item.laundry) {
                if (item.laundry.washingAndIroningSpeedySelected === true) {
                    item.laundry.washingAndIroningSpeedySelected = false
                    item.laundry["washingAndIroningSelected"] = true
                    item.itemPrice = item.itemPrice - item.laundry.washingAndIroningSpeedyPrice
                    item.sumPrice = item.itemPrice * item.itemQuantity
                }
            }
        });
    }
}

const removeProductFromShoppongCart = (state, { productIndex }) => {
    const newItems = state.items.map(product => _.cloneDeep(product))

    newItems.splice(productIndex, 1)

    return returnStateAfterItemCheck({ state, newItems, itemsCount: state.itemsCount - 1 })
}

const calculatePrice = (item) => {
    let itemPrice = 0
    item.sumPrice = 0

    if (item.laundry) {
        let laundryPrice = 0
        if (item.laundry.perKilo) {
            if (item.laundry.washingPerKiloPrice > 0) {
                laundryPrice = item.laundry.washingPerKiloPrice
            } else {
                laundryPrice = item.laundry.washingAndIroningPerKiloPrice
            }
        }
        else if (item.laundry.noLaundrySelected) {
            laundryPrice = 0
        } else if (item.laundry.washingAndIroningSelected) {
            laundryPrice = item.laundry.washingAndIroningPrice
        } else
            laundryPrice = item.laundry.washingAndIroningPrice + (item.laundry.washingAndIroningSpeedy === true ? item.laundry.washingAndIroningSpeedyPrice : 0)
        item.sumPrice = laundryPrice * item.itemQuantity
        itemPrice = laundryPrice
    }
    if (item.tailoring) {
        let tailoring = 0
        if (item.tailoring.buttonSelected) {
            tailoring = tailoring + (item.tailoring.buttonQuantity * item.tailoring.buttonPrice)
        }
        if (item.tailoring.zipperSelected) {
            tailoring = tailoring + (item.tailoring.zipperQuantity * item.tailoring.zipperPrice)
        }
        if (item.tailoring.hemSelected) {
            tailoring = tailoring + (item.tailoring.hemQuantity * item.tailoring.hemPrice)
        }
        item.sumPrice = item.sumPrice + tailoring
    }
    if (item.delivery) {
        let delivery = 0
        if (item.delivery.packedSelected) {
            delivery = delivery + item.delivery.packedPrice
        }
        if (item.delivery.hangerSelected) {
            delivery = delivery + item.delivery.hangerPrice
        }
        item.sumPrice = item.sumPrice + delivery * item.itemQuantity
        itemPrice = itemPrice + delivery
    }
    if (item.additional) {
        let additional = 0
        if (item.additional.decorationSelected) {
            additional = additional + item.additional.decorationPrice
        }
        if (item.additional.platedSelected) {
            additional = additional + item.additional.platedPrice
        }
        if (item.additional.sequinedSelected) {
            additional = additional + item.additional.sequinedPrice
        }
        item.sumPrice = item.sumPrice + additional * item.itemQuantity
        itemPrice = itemPrice + additional
    }

    if (item.leather) {
        let leatherPrice = 0
        if (item.leather.velourSelected) {
            leatherPrice = leatherPrice + item.leather.velourPrice
        }
        if (item.leather.napaSelected) {
            leatherPrice = leatherPrice + item.leather.napaPrice
        }
        item.sumPrice = item.sumPrice + leatherPrice * item.itemQuantity
        itemPrice = itemPrice + leatherPrice
    }

}

const noLaundryCheck = (state, { itemIndex }) => {

    const newItems = state.items.map(product => _.cloneDeep(product))

    newItems.forEach((item, index) => {
        if (index === itemIndex) {
            item.laundry.noLaundrySelected = true
            item.laundry.washingAndIroningSelected = false
            item.laundry.washingAndIroningSpeedySelected = false
            item.laundry.withSpotCleaningSelected = false
            calculatePrice(item)
        } else {
            if (item.laundry && item.laundry.washingAndIroningSpeedySelected === true) {
                item.laundry.noLaundrySelected = false
                item.laundry.washingAndIroningSelected = true
                item.laundry.withSpotCleaningSelected = false
                item.laundry.washingAndIroningSpeedySelected = false
                calculatePrice(item)
            }
        }
    });


    return returnStateAfterItemCheck({ state, newItems })
}

const washingAndIroningCheck = (state, { itemIndex }) => {

    const newItems = state.items.map(product => _.cloneDeep(product))
    newItems.forEach((item, index) => {
        if (index === itemIndex) {
            item.laundry.noLaundrySelected = false
            item.laundry.washingAndIroningSelected = true
            item.laundry.withSpotCleaningSelected = false
            item.laundry.washingAndIroningSpeedySelected = false
            calculatePrice(item)
        } else {
            if (item.laundry && item.laundry.washingAndIroningSpeedySelected === true) {
                item.laundry.noLaundrySelected = false
                item.laundry.washingAndIroningSelected = true
                item.laundry.withSpotCleaningSelected = false
                item.laundry.washingAndIroningSpeedySelected = false
                calculatePrice(item)
            }
        }
    });

    return returnStateAfterItemCheck({ state, newItems })
}

const withSpotCleaningCheck = (state, { itemIndex }) => {

    const newItems = state.items.map(product => _.cloneDeep(product))

    newItems.forEach((item, index) => {
        if (index === itemIndex) {
            item.laundry.noLaundrySelected = false
            item.laundry.washingAndIroningSelected = true
            item.laundry.withSpotCleaningSelected = !item.laundry.withSpotCleaningSelected
            item.laundry.washingAndIroningSpeedySelected = false
            calculatePrice(item)
        } else {
            if (item.laundry && item.laundry.washingAndIroningSpeedySelected === true) {
                item.laundry.noLaundrySelected = false
                item.laundry.washingAndIroningSelected = true
                item.laundry.withSpotCleaningSelected = false
                item.laundry.washingAndIroningSpeedySelected = false
                calculatePrice(item)
            }
        }
    });

    return returnStateAfterItemCheck({ state, newItems })
}

const washingAndIroningSpeedyCheck = (state, { itemIndex }) => {

    const newItems = state.items.map(product => _.cloneDeep(product))

    newItems.forEach(item => {
        if (item.laundry) {
            item.laundry.noLaundrySelected = false
            item.laundry.washingAndIroningSelected = false
            item.laundry.withSpotCleaningSelected = false
            item.laundry.washingAndIroningSpeedySelected = true
            calculatePrice(item)
        }
    });

    return returnStateAfterItemCheck({ state, newItems })
}


const buttonCheck = (state, { itemIndex }) => {

    const newItems = state.items.map(product => _.cloneDeep(product))

    if (!newItems[itemIndex].tailoring.buttonSelected)
        newItems[itemIndex].tailoring.buttonQuantity = 1
    newItems[itemIndex].tailoring.buttonSelected = !newItems[itemIndex].tailoring.buttonSelected
    calculatePrice(newItems[itemIndex])

    return returnStateAfterItemCheck({ state, newItems })
}

const hemCheck = (state, { itemIndex }) => {

    const newItems = state.items.map(product => _.cloneDeep(product))

    if (!newItems[itemIndex].tailoring.hemSelected)
        newItems[itemIndex].tailoring.hemQuantity = 1
    newItems[itemIndex].tailoring.hemSelected = !newItems[itemIndex].tailoring.hemSelected
    calculatePrice(newItems[itemIndex])

    return returnStateAfterItemCheck({ state, newItems })
}

const zipperCheck = (state, { itemIndex }) => {

    const newItems = state.items.map(product => _.cloneDeep(product))

    if (!newItems[itemIndex].tailoring.zipperSelected)
        newItems[itemIndex].tailoring.zipperQuantity = 1
    newItems[itemIndex].tailoring.zipperSelected = !newItems[itemIndex].tailoring.zipperSelected
    calculatePrice(newItems[itemIndex])

    return returnStateAfterItemCheck({ state, newItems })
}

const returnStateAfterItemCheck = ({ state, newItems, itemsCount }) => {
    const itemsPrice = calculateItemsPrice(newItems)
    const deliveryDates = deliveryDatesAvailable(newItems)
    return ({
        ...state,
        itemsCount: itemsCount !== undefined ? itemsCount : state.itemsCount,
        items: newItems,
        itemsPrice: itemsPrice,
        shippingPrice: 0,
        pickupLocationConfirmed: false,
        deliveryLocationConfirmed: false,
        deliveryDatesAvailable: deliveryDates,
        numberOfDeliveries: deliveryDates.length !== 2 ? 1 : state.numberOfDeliveries,
        ...calculateTotalPrice({ itemsPrice, shippingPrice: 0, codeType: state.codeType, codeValue: state.codeValue })
    })
}

const quantityAdd = (state, { itemIndex, itemType }) => {
    const newItems = state.items.map(product => _.cloneDeep(product))

    switch (itemType) {
        case "item":
            newItems[itemIndex].itemQuantity = (newItems[itemIndex].itemQuantity || 1) + 1
            // newItems[itemIndex].sumPrice = newItems[itemIndex].itemPrice * newItems[itemIndex].itemQuantity
            calculatePrice(newItems[itemIndex])

            break;
        default:
            newItems[itemIndex].tailoring[`${itemType}Quantity`] = (newItems[itemIndex].tailoring[`${itemType}Quantity`] || 1) + 1
            calculatePrice(newItems[itemIndex])
            break;
    }

    return returnStateAfterItemCheck({ state, newItems })
}

const quantityRemove = (state, { itemIndex, itemType }) => {

    const newItems = state.items.map(product => _.cloneDeep(product))

    switch (itemType) {
        case "button":
            newItems[itemIndex].tailoring.buttonQuantity = (newItems[itemIndex].tailoring.buttonQuantity || 1) - 1
            calculatePrice(newItems[itemIndex])
            break;
        case "zipper":
            newItems[itemIndex].tailoring.zipperQuantity = (newItems[itemIndex].tailoring.zipperQuantity || 1) - 1
            calculatePrice(newItems[itemIndex])
            break;
        case "hem":
            newItems[itemIndex].tailoring.hemQuantity = (newItems[itemIndex].tailoring.hemQuantity || 1) - 1
            calculatePrice(newItems[itemIndex])
            break;
        case "item":
            newItems[itemIndex].itemQuantity = (newItems[itemIndex].itemQuantity || 1) - 1
            calculatePrice(newItems[itemIndex])

            break;
        default:
            break;
    }

    return returnStateAfterItemCheck({ state, newItems })
}

const deliveryCheck = (state, { itemIndex }) => {

    const newItems = state.items.map(product => _.cloneDeep(product))

    if (newItems[itemIndex].delivery.packed && newItems[itemIndex].delivery.hanger) {
        newItems[itemIndex].delivery.packedSelected = !newItems[itemIndex].delivery.packedSelected
        newItems[itemIndex].delivery.hangerSelected = !newItems[itemIndex].delivery.packedSelected
    }

    calculatePrice(newItems[itemIndex])

    return returnStateAfterItemCheck({ state, newItems })
}


const leatherTypeCheck = (state, { itemIndex }) => {

    const newItems = state.items.map(product => _.cloneDeep(product))

    newItems[itemIndex].leather.napaSelected = !newItems[itemIndex].leather.napaSelected
    newItems[itemIndex].leather.velourSelected = !newItems[itemIndex].leather.velourSelected
    newItems[itemIndex].itemPrice = (newItems[itemIndex].leather.velourSelected ?
        newItems[itemIndex].leather.velourPrice : newItems[itemIndex].leather.napaPrice)
    newItems[itemIndex].sumPrice = newItems[itemIndex].itemPrice * newItems[itemIndex].itemQuantity

    return returnStateAfterItemCheck({ state, newItems })
}

const additionalCheck = (state, { itemIndex, checkType }) => {
    const newItems = state.items.map(product => _.cloneDeep(product))
    newItems[itemIndex].additional[checkType] = !newItems[itemIndex].additional[checkType]
    calculatePrice(newItems[itemIndex])

    return returnStateAfterItemCheck({ state, newItems })
}

const applyPromoCodeSuccess = (state, { code, codeType, value }) => ({
    ...state,
    items: JSON.parse(JSON.stringify(state.items)),
    [codeType]: code,
    codeUsed: true,
    codeType: codeType,
    codeValue: value,
    ...calculateTotalPrice({ itemsPrice: state.itemsPrice, shippingPrice: state.shippingPrice, codeType, codeValue: value })
})

const deleteCode = (state) => {
    return ({
        ...state,
        welcomecode: null,
        giftcard: null,
        codeUsed: false,
        codeType: null,
        items: JSON.parse(JSON.stringify(state.items)),
        ...calculateTotalPrice({ itemsPrice: state.itemsPrice, shippingPrice: state.shippingPrice })
    })
}

const calculateShippingPrice = ({ itemsPrice, selfPickUp, selfDelivery, pickupLocationConfirmed, deliveryLocationConfirmed, isVip, numberOfDeliveries }) => {

    if (isVip)
        return 0

    if (pickupLocationConfirmed !== true && deliveryLocationConfirmed !== true)
        return 0

    if (itemsPrice === 0)
        return 0

    if (itemsPrice > 30)
        return 0

    if (selfPickUp && selfDelivery)
        return 0

    let shippingPrice = 0

    if (!selfPickUp && pickupLocationConfirmed === true)
        shippingPrice = shippingPrice + 7.5

    if (!selfDelivery && deliveryLocationConfirmed === true) {
        if (numberOfDeliveries === 2) {
            shippingPrice = shippingPrice + 15
        } else {
            shippingPrice = shippingPrice + 7.5
        }
    }

    return shippingPrice
}

const confirmWithoutAddress = (state, { addressType }) => {
    let shippingPrice
    switch (addressType) {
        case 1:
            shippingPrice = calculateShippingPrice({
                itemsPrice: state.itemsPrice,
                selfPickUp: true,
                selfDelivery: state.selfDelivery,
                pickupLocationConfirmed: true,
                deliveryLocationConfirmed: state.deliveryLocationConfirmed,
                numberOfDeliveries: state.numberOfDeliveries
            })
            return ({
                ...state,
                selfPickUp: true,
                pickupLocationConfirmed: true,
                shippingPrice: shippingPrice,
                items: JSON.parse(JSON.stringify(state.items)),
                ...calculateTotalPrice({ itemsPrice: state.itemsPrice, shippingPrice: shippingPrice, codeType: state.codeType, codeValue: state.codeValue })
            })
        default:
            shippingPrice = calculateShippingPrice({
                itemsPrice: state.itemsPrice,
                selfPickUp: state.selfPickUp,
                selfDelivery: true,
                pickupLocationConfirmed: state.pickupLocationConfirmed,
                deliveryLocationConfirmed: true,
                numberOfDeliveries: state.numberOfDeliveries
            })
            return ({
                ...state,
                shippingPrice: shippingPrice,
                selfDelivery: true,
                deliveryLocationConfirmed: true,
                numberOfDeliveries: 1,
                items: JSON.parse(JSON.stringify(state.items)),
                ...calculateTotalPrice({ itemsPrice: state.itemsPrice, shippingPrice: shippingPrice, codeType: state.codeType, codeValue: state.codeValue })
            })
    }
}


const confirmWithPickUpAddress = (state, { value, isVip }) => {

    const shippingPrice = calculateShippingPrice({
        itemsPrice: state.itemsPrice,
        selfPickUp: false,
        selfDelivery: state.selfDelivery,
        pickupLocationConfirmed: value,
        deliveryLocationConfirmed: value ? state.deliveryLocationConfirmed : false,
        isVip,
        numberOfDeliveries: state.numberOfDeliveries
    })
    return ({
        ...state,
        shippingPrice: shippingPrice,
        selfPickUp: false,
        selfDelivery: value ? state.selfDelivery : false,
        pickupLocationConfirmed: value,
        deliveryLocationConfirmed: value ? state.deliveryLocationConfirmed : false,
        items: JSON.parse(JSON.stringify(state.items)),
        ...calculateTotalPrice({ itemsPrice: state.itemsPrice, shippingPrice: shippingPrice, codeType: state.codeType, codeValue: state.codeValue })
    })
}

const confirmWithDeliveryAddress = (state, { value, isVip }) => {

    const shippingPrice = calculateShippingPrice({
        itemsPrice: state.itemsPrice,
        selfPickUp: state.selfPickUp,
        selfDelivery: false,
        pickupLocationConfirmed: state.pickupLocationConfirmed,
        deliveryLocationConfirmed: value,
        isVip,
        numberOfDeliveries: state.numberOfDeliveries
    })

    return ({
        ...state,
        shippingPrice: shippingPrice,
        selfDelivery: false,
        deliveryLocationConfirmed: value,
        items: JSON.parse(JSON.stringify(state.items)),
        ...calculateTotalPrice({ itemsPrice: state.itemsPrice, shippingPrice: shippingPrice, codeType: state.codeType, codeValue: state.codeValue })
    })
}

const changeNumberOfDeliveries = (state, { number }) => {

    let newState = _.cloneDeep(state)
    newState.numberOfDeliveries = parseInt(number, 10)

    return newState
}

const notesChange = (state, { itemIndex, text }) => {
    let newState = _.cloneDeep(state)

    newState.items[itemIndex]["notes"] = text

    return newState
}

const giftCardItemSubmit = (state, { values, itemIndex }) => {
    let newState = _.cloneDeep(state)

    newState.items[itemIndex].giftCard.message = values.message
    newState.items[itemIndex].giftCard.email = values.email
    newState.items[itemIndex].giftCard.name = values.name
    newState.items[itemIndex].giftCard.confirmed = !newState.items[itemIndex].giftCard.confirmed

    return newState
}

export default (state = initialState(), action) => {
    let shoppingCart = reducer(state, action)
    localStorage.setItem("shoppingCart", JSON.stringify(shoppingCart))
    return shoppingCart
};

const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.GIFT_CARD_ITEM_SUBMIT:
            return giftCardItemSubmit(state, action)
        case actionTypes.NOTES_CHANGE:
            return notesChange(state, action)
        case actionTypes.CHANGE_NUMBER_OF_DELIVERIES:
            return changeNumberOfDeliveries(state, action)
        case actionTypes.CONFIRM_WITHOUT_ADDRESS:
            return confirmWithoutAddress(state, action)
        case actionTypes.DELETE_CODE:
            return deleteCode(state, action)
        case actionTypes.APPLY_CODE_SUCCESS:
            return applyPromoCodeSuccess(state, action)
        case actionTypes.REMOVE_ALL_PRODUCTS_FROM_SHOPPING_CART:
            return removeAllProductsFromShoppongCart(state, action)
        case actionTypes.ADDITIONAL_CHECK:
            return additionalCheck(state, action)
        case actionTypes.LEATHER_TYPE_CHECK:
            return leatherTypeCheck(state, action)
        case actionTypes.DELIVERY_CHECK:
            return deliveryCheck(state, action)
        case actionTypes.QUANTITY_ADD:
            return quantityAdd(state, action)
        case actionTypes.QUANTITY_REMOVE:
            return quantityRemove(state, action)
        case actionTypes.ZIPPER_CHECK:
            return zipperCheck(state, action)
        case actionTypes.HEM_CHECK:
            return hemCheck(state, action)
        case actionTypes.BUTTON_CHECK:
            return buttonCheck(state, action)
        case actionTypes.WASHING_AND_IRONING_SPEEDY_CHECK:
            return washingAndIroningSpeedyCheck(state, action)
        case actionTypes.WITH_SPOT_CLEANING_CHECK:
            return withSpotCleaningCheck(state, action)
        case actionTypes.WASHING_AND_IRONING_CHECK:
            return washingAndIroningCheck(state, action)
        case actionTypes.NO_LAUNDRY_CHECK:
            return noLaundryCheck(state, action)
        case actionTypes.REMOVE_FROM_SHOPPING_CART:
            return removeProductFromShoppongCart(state, action)
        case actionTypes.ADD_TO_SHOPPING_CART:
            return addProductToShoppongCart(state, action);
        case actionTypes.CONFIRM_PICKUP_LOCATION:
            return confirmWithPickUpAddress(state, action)
        case actionTypes.CONFIRM_DELIVERY_LOCATION:
            return confirmWithDeliveryAddress(state, action)
        case 'SUBMIT_ORDER_SUCCESS':
                return {
                    ...state,
                    paymentLink: action.payload.link,
                    orderId: action.payload.id
                };
        default:
            return state;
    }
}

export const getItemsCount = state => state.shoppingCart.itemsCount
export const getItems = state => state.shoppingCart.items
export const getItemByIndex = (state, itemIndex) => state.shoppingCart.items[itemIndex]
export const getLaundryItem = (state, itemIndex) => state.shoppingCart.items[itemIndex].laundry
export const getFinalPrice = (state) => state.shoppingCart.finalPrice

export const showOnlyPaymentOnCheckout = state => {
    return state.shoppingCart.items.every(item => {
        return item.giftCard
    })
}

export const checkoutPageEnabled = state => {
    return state.shoppingCart.items.filter(t => t.giftCard).every(item => {
        return item.giftCard.confirmed
    }) && state.shoppingCart.totalPrice > 0
}

export const onlyGiftCards = state => {
    return state.shoppingCart.items.every(item => item.giftCard)
}

export const hasSpeedyItem = state => {
    return state
        .shoppingCart
        .items
        .map(item => item.laundry || { washingAndIroningSpeedySelected: false })
        .every(laundryItem => laundryItem.washingAndIroningSpeedySelected === true);
}

export const speedyOptionDisabled = state => state
    .shoppingCart
    .items
    .some(t => t.longProcessing || (t.laundry ? t.laundry.washingAndIroningSpeedy === false : false))