import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import ShoppingCartTotals from './ShoppingCartTotals';
import { Redirect } from 'react-router'
import {
    confirmPickUpLocation,
    submitOrder,
    confirmDeliveryLocation,
    getAddresses,
    confirmWithoutAddress,
    removeAllProductsFromShoppingCart,
    authModalToggle,
    submitOrderAndGoToDetails
} from "../../store/actions";
import {
    Card,
    CardBody,
    CardHeader,
    Row,
    CardFooter,
    Col, Modal,
    ModalHeader,
    ModalBody,
    Button,
    ModalFooter
} from 'reactstrap';
import AddressModal from "../auth/UserProfile/AddressesTab/AddressModal.js";
import CheckoutPageStepBox from './CheckoutPageStepBox';
import {
    getDefaultPickupAddress,
    getDefaultDeliveryAddress,
    getBillingAddress
} from "../../store/reducers/addresses";
import Calendar from 'react-calendar';
import moment from "moment-business-days";
import ProductLoader from '../products/ProductLoader';
import { showOnlyPaymentOnCheckout } from '../../store/reducers/shoppingCart';
moment.updateLocale('de', {
    holidays: [
        '26-05-2022',
        '06-06-2022',
        '01-08-2022',
        '25-12-2022',
        '26-12-2022',
        '01-01-2023',
        '07-04-2023',
        '10-04-2023',
        '01-05-2023',
        '18-05-2023',
        '29-05-2023',
        '01-08-2023',
        '25-12-2023',
        '26-12-2023',
        '01-05-2024',
        '09-05-2024',
        '20-05-2024',
        '01-08-2024',
        '25-12-2024',
        '26-12-2024'
    ],
    holidayFormat: 'DD-MM-YYYY',
    workingWeekdays: [1, 2, 3, 4, 5]
});

const getStartDate = () => {
    const currentDate = new Date()
    return moment(`${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`, 'DD-MM-YYYY').nextBusinessDay()._d
}

const getDeliveryDate = (minDeliveryDate) => {
    //console.log('srdjan', minDeliveryDate);
    const currentDate = new Date()
    let currentWorkingDay = moment(`${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`, 'DD-MM-YYYY').nextBusinessDay()._d

    return moment(`${currentWorkingDay.getDate()}-${currentWorkingDay.getMonth() + 1}-${currentWorkingDay.getFullYear()}`, 'DD-MM-YYYY').businessAdd(minDeliveryDate)._d
}

class CheckoutPage extends Component {

    state = {
        showModal: false,
        addressForEdit: {},
        isCalendarOpen: false,
        date: getStartDate(),
        fromToTime: '07:00 - 09:00',
        currentFromToDateBeingEdited: '',
        pickUpDate: getStartDate(),
        deliveryDate: getDeliveryDate(this.props.minDeliveryDate),
        pickUpTime: '07:00 - 09:00',
        deliveryTime: '07:00 - 09:00',
    };

    componentDidMount() {
        const { token, OnGetAddresses } = this.props;
        OnGetAddresses(token);
    }

    componentDidUpdate(prevProps) {

        if (this.props.paymentLink && prevProps.paymentLink !== this.props.paymentLink) {
            this.redirectToPayment(this.props.paymentLink);
        } else if (this.props.orderId && prevProps.orderId !== this.props.orderId) {
            this.setState( {loading: false} )
            return <Redirect to={`/payengine?status=success&order=${this.props.orderIdsonp}`} />
        }

    }

    editClickHandler = (e, address) => {
        this.setState({ showModal: true, addressForEdit: { ...address } })
    };

    modalToggleHandler = () => {
        this.setState({ showModal: false })
    }


    confirmDeliveryLocation = (value) => {
        this.props.confirmDeliveryLocation(value);
    };

    checkLoggedIn = () => {
        if (!this.props.token) {
            this.props.OnAuthModalToggle();
            // Trigger loggin modal
        }
    };

    handleOrderSubmit = () => {

        this.setState( {loading: true} )
        
        const { defaultPickupAddress, defaultDeliveryAddress, token, showOnlyPayment, onSubmitOrder, onSubmitOrderAndGoToDetails, shoppingCart, userName } = this.props
        const { pickUpTime, deliveryTime, pickUpDate, deliveryDate } = this.state;

        const payload = {
            pickUpAddress: defaultPickupAddress,
            deliveryAddress: defaultDeliveryAddress,
            billingAddress: getBillingAddress,
            items: shoppingCart.items,
            welcomecode: shoppingCart.welcomecode,
            giftcard: shoppingCart.giftcard,
            paymentOnly: showOnlyPayment,
            codeUsed: shoppingCart.codeUsed,
            codeType: shoppingCart.codeType,
            codeValue: shoppingCart.codeValue,
            totalPrice: shoppingCart.totalPrice,
            token
        };

        if (!shoppingCart.selfPickUp) {
            const [pickUpTimeFrom, pickUpTimeTo] = pickUpTime.split('-');
            payload.pickUpAddress.pickUpDate = pickUpDate;
            payload.pickUpAddress.pickUpTimeFrom = new Date(pickUpDate).setHours(parseInt(pickUpTimeFrom))
            payload.pickUpAddress.pickUpTimeTo = new Date(pickUpDate).setHours(parseInt(pickUpTimeTo));
            payload.selfPickUp = false
        } else {
            payload.selfPickUp = true
        }

        if (!shoppingCart.selfDelivery) {
            const [deliveryTimeFrom, deliveryTimeTo] = deliveryTime.split('-');
            payload.deliveryAddress.deliveryDate = deliveryDate;
            payload.deliveryAddress.deliveryTimeFrom = new Date(deliveryDate).setHours(parseInt(deliveryTimeFrom))
            payload.deliveryAddress.deliveryTimeTo = new Date(deliveryDate).setHours(parseInt(deliveryTimeTo))
            payload.selfDelivery = false
        } else {
            payload.selfDelivery = true
        }
        if (!(shoppingCart.codeUsed && shoppingCart.totalPrice === 0)) {
            // Create an order intent on ConcardIs.
            const { shoppingCart } = this.props;
            
            this.state.payload = payload
            onSubmitOrder(payload); // this triggers onSubmitOrderSuccess which redirects to the payment page
        } else {
            this.setState({loading: false})
            onSubmitOrderAndGoToDetails(payload);
        }
    };

    redirectToPayment = (paymentLink) => {
        window.location.href = paymentLink   
    }

    onUpdateFromToDate = (name, e) => {
        this.setState({ currentFromToDateBeingEdited: name });
        this.toggleDateTimePicker();
    };

    toggleDateTimePicker = () => {
        this.setState({ isCalendarOpen: !this.state.isCalendarOpen })
    };

    onDateChange = (currentFromToDateBeingEdited, date, time) => {
        if (currentFromToDateBeingEdited === 'pickUpDate') {
            const { minDeliveryDate } = this.props;
            const { deliveryDate } = this.state
            if (!time) {
                time = this.state.pickUpTime
            }

            let addDay = 0;
            if (time === '17:00 - 19:00') {
                addDay = 1;
            }
            const businessDay = moment(`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`, 'DD-MM-YYYY').businessAdd(minDeliveryDate + addDay)._d
            if (deliveryDate < businessDay) {
                this.setState({
                    deliveryDate: businessDay,
                    pickUpDate: date,
                    pickUpTime: this.checkIfTimeIsOk(date, time),
                    deliveryTime: this.checkIfTimeIsOk(businessDay, this.state.deliveryTime)
                })
            }
            else {
                this.setState({
                    pickUpDate: date,
                    pickUpTime: this.checkIfTimeIsOk(date, time)
                });
            }
        } else {
            if (!time) {
                time = this.state.deliveryTime
            }
            this.setState({
                deliveryDate: date,
                deliveryTime: this.checkIfTimeIsOk(date, time)
            });
        }
    }

    checkIfTimeIsOk = (date, timePeriod) => {
        if (moment(`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`, 'DD-MM-YYYY').isoWeekday() === 6) {
            const disabledTimes = ['17:00 - 19:00', '15:00 - 17:00']
            if (disabledTimes.includes(timePeriod)) {
                return '13:00 - 15:00'
            }
        }
        return timePeriod
    }

    setTime = e => {
        const { currentFromToDateBeingEdited } = this.state;
        if (currentFromToDateBeingEdited === 'pickUpDate') {
            this.onDateChange(currentFromToDateBeingEdited, this.state.pickUpDate, e.target.id);
        } else {
            this.onDateChange(currentFromToDateBeingEdited, this.state.deliveryDate, e.target.id);
        }
    };

    onDateModalSubmit = () => {
        this.toggleDateTimePicker();
    };

    disabledPickupDates = (activeStartDate, date) => {
        const { minDeliveryDate } = this.props;
        const maxDate = moment(moment().add(1, 'months')).toDate()
        const daysDisabled = moment(`${maxDate.getDate()}-${maxDate.getMonth() + 1}-${maxDate.getFullYear()}`, 'DD-MM-YYYY').businessAdd(-minDeliveryDate)._d
        return !(moment(`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`, 'DD-MM-YYYY').isBusinessDay()) || date < moment() || date > daysDisabled
    };

    disabledDeliveryDate = (activeStartDate, date) => {
        const { pickUpDate } = this.state
        const { minDeliveryDate } = this.props;
        let extraDay = 0;
        if (this.state.pickUpTime === '17:00 - 19:00') {
            extraDay = 1;
        }
        return date < moment(`${pickUpDate.getDate()}-${pickUpDate.getMonth() + 1}-${pickUpDate.getFullYear()}`, 'DD-MM-YYYY').businessAdd(minDeliveryDate + extraDay)._d || !(moment(`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`, 'DD-MM-YYYY').isBusinessDay())
    };

    render() {
        const { deliveryLocationConfirmed, pickupLocationConfirmed, shoppingCart, token, loading, isVip, showOnlyPayment } = this.props;

        if (shoppingCart.items.length === 0) {
            return <Redirect to="/products" />
        }

        if (!token) {
            this.props.OnAuthModalToggle()
            return <Redirect to="/shopping-cart/page1" />
        }

        if (loading) {
            return <ProductLoader />
        }

        const { isCalendarOpen, currentFromToDateBeingEdited, pickUpDate, deliveryDate } = this.state;
        const dateFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const fromToTime = currentFromToDateBeingEdited === 'pickUpDate' ? this.state.pickUpTime : this.state.deliveryTime;

        const timeSlotDisabled = (currentFromToDateBeingEdited === 'pickUpDate' && pickUpDate.getDay() === 6) ||
            (currentFromToDateBeingEdited === 'deliveryDate' && deliveryDate.getDay() === 6);

        return (

            <div className="checkout-out-page-wrapper">
                <AddressModal modalShowed={this.state.showModal} address={this.state.addressForEdit} modalToggle={this.modalToggleHandler} />

                <Modal isOpen={isCalendarOpen} toggle={this.toggleDateTimePicker} centered size="sm" >
                    <ModalHeader toggle={this.toggleDateTimePicker}>Datum und Uhrzeit Abholung</ModalHeader>
                    <ModalBody>
                        <Calendar
                            value={currentFromToDateBeingEdited === 'pickUpDate' ? this.state.pickUpDate : this.state.deliveryDate}
                            locale='de-DE'
                            onChange={date => this.onDateChange(currentFromToDateBeingEdited, date)}
                            maxDate={moment(moment().add(1, 'months')).toDate()}
                            tileDisabled={({ activeStartDate, date }) => currentFromToDateBeingEdited === 'pickUpDate' ? this.disabledPickupDates(activeStartDate, date) : this.disabledDeliveryDate(activeStartDate, date)}
                        />
                        <br />
                        <Row className='pb-2'>
                            <Col>
                                <Button
                                    id="07:00 - 09:00"
                                    size='sm'
                                    block
                                    outline
                                    color="info"
                                    active={fromToTime === '07:00 - 09:00'}
                                    onClick={this.setTime}
                                >07:00 - 09:00</Button>
                            </Col>
                            <Col>
                                <Button
                                    id='09:00 - 11:00'
                                    size='sm'
                                    block
                                    outline
                                    color="info"
                                    className="time-btn"
                                    active={fromToTime === '09:00 - 11:00'}
                                    onClick={this.setTime}
                                >09:00 - 11:00</Button>
                            </Col>
                        </Row>
                        <Row className='pb-2'>
                            <Col>
                                <Button
                                    id='11:00 - 13:00'
                                    size='sm'
                                    block
                                    outline
                                    color="info"
                                    active={fromToTime === '11:00 - 13:00'}
                                    onClick={this.setTime}
                                >11:00 - 13:00</Button>
                            </Col>
                            <Col>
                                <Button
                                    id='13:00 - 15:00'
                                    size='sm'
                                    block
                                    outline
                                    color="info"
                                    active={fromToTime === '13:00 - 15:00'}
                                    onClick={this.setTime}
                                >13:00 - 15:00</Button>
                            </Col>
                        </Row>
                        <Row className='pb-2'>
                            <Col>
                                <Button
                                    id='15:00 - 17:00'
                                    size='sm'
                                    block
                                    outline
                                    color="info"
                                    active={fromToTime === '15:00 - 17:00'}
                                    onClick={this.setTime}
                                    disabled={timeSlotDisabled}
                                >15:00 - 17:00</Button>
                            </Col>
                            <Col>
                                <Button
                                    id='17:00 - 19:00'
                                    size='sm'
                                    block
                                    outline
                                    color="info"
                                    active={fromToTime === '17:00 - 19:00'}
                                    onClick={this.setTime}
                                    disabled={timeSlotDisabled}
                                >17:00 - 19:00</Button>
                            </Col>
                        </Row>

                    </ModalBody>
                    <div style={{ alignSelf: 'center', textAlign: "center" }}>
                        <h5>{`${(currentFromToDateBeingEdited === 'pickUpDate' ? this.state.pickUpDate : this.state.deliveryDate).toLocaleString('de-DE', dateFormatOptions)} `}</h5>
                        <h5>{currentFromToDateBeingEdited === 'pickUpDate' ? this.state.pickUpTime : this.state.deliveryTime}</h5>
                    </div>
                    <ModalFooter>
                        {/* <Button block size='sm' color="primary" onClick={this.onDateModalSubmit}>BESTÄTIGEN</Button>
                        <Button block size='sm' color="secondary" className="no-margin-top" onClick={this.toggle}>ABBRECHEN</Button> */}
                        <Button block size='sm' color="primary" className="no-margin-top" style={{ textTransform: "uppercase" }} onClick={this.onDateModalSubmit}>Schließen</Button>
                    </ModalFooter>
                </Modal>
                <Row style={{ justifyContent: 'center' }}>
                    {
                        !showOnlyPayment &&
                        <>
                            <Col lg="4" style={{ marginBottom: "30px" }}>
                                <CheckoutPageStepBox
                                    icon={require("../../img/checkoutPage/pickup-icon.png")}
                                    headerText="Abholung"
                                    confirmed={this.props.pickupLocationConfirmed}
                                    editVisible={!this.props.pickupLocationConfirmed}
                                    confirm={(value) => this.props.confirmPickUpLocation(value, isVip)}
                                    needsConfirmation={false}
                                    address={this.props.defaultPickupAddress}
                                    editClick={(e) => this.editClickHandler(e, this.props.defaultPickupAddress)}
                                    updateFromToDateTime={(e) => this.onUpdateFromToDate('pickUpDate', e)}
                                    date={`${this.state.pickUpDate.toLocaleString('de-DE', dateFormatOptions)}`}
                                    time={this.state.pickUpTime}
                                    disabledButton={false}
                                    confirmWithoutAddress={() => this.props.confirmWithoutAddress(1)}
                                    noAddress={shoppingCart.selfPickUp}
                                    noAddressButtonText="Im Laden vorbeibringen"
                                />
                            </Col>
                            <Col lg="4" style={{ marginBottom: "30px" }}>
                                <CheckoutPageStepBox
                                    icon={require('../../img/checkoutPage/delevery-icon.png')}
                                    headerText="Lieferung"
                                    editVisible={this.props.pickupLocationConfirmed && !this.props.deliveryLocationConfirmed}
                                    needsConfirmation={!this.props.pickupLocationConfirmed}
                                    confirmed={this.props.deliveryLocationConfirmed}
                                    confirm={(value) => this.props.confirmDeliveryLocation(value, isVip)}
                                    address={this.props.defaultDeliveryAddress}
                                    editClick={(e) => this.editClickHandler(e, this.props.defaultDeliveryAddress)}
                                    updateFromToDateTime={(e) => this.onUpdateFromToDate('deliveryDate', e)}
                                    date={`${this.state.deliveryDate.toLocaleString('de-DE', dateFormatOptions)}`}
                                    time={this.state.deliveryTime}
                                    disabledButton={!this.props.pickupLocationConfirmed}
                                    confirmWithoutAddress={() => this.props.confirmWithoutAddress(2)}
                                    noAddress={shoppingCart.selfDelivery}
                                    noAddressButtonText="Im Laden abholen"
                                />
                            </Col>
                        </>
                    }

                    <Col lg="4" style={{ marginBottom: "30px" }}>
                        <Card className="checkout-step-box">
                            <CardHeader>
                                <img alt="" src={require('../../img/checkoutPage/confirm-icon.png')} />
                                Auftragszusammenfassung
                            </CardHeader>
                            <CardBody>
                                <ShoppingCartTotals />
                            </CardBody>
                            <CardFooter>
                                <Button
                                    color="info"
                                    className={`checkout-btn ${this.state.loading ? ' loading-btn' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        
                                        this.handleOrderSubmit()
                                    }}
                                    disabled={((!pickupLocationConfirmed || !deliveryLocationConfirmed) && !showOnlyPayment)}
                                >
                                   <span>BESTÄTIGEN UND ZAHLEN</span>
                                </Button>
                            </CardFooter>
                        </Card>

                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.addresses.loading,
    pickupLocationConfirmed: state.shoppingCart.pickupLocationConfirmed,
    deliveryLocationConfirmed: state.shoppingCart.deliveryLocationConfirmed,
    shoppingCart: state.shoppingCart,
    token: state.auth.token,
    isVip: state.auth.isVip,
    defaultPickupAddress: getDefaultPickupAddress(state),
    defaultDeliveryAddress: getDefaultDeliveryAddress(state),
    getBillingAddress: getBillingAddress(state),
    showOnlyPayment: showOnlyPaymentOnCheckout(state),
    minDeliveryDate: Math.max(...state.shoppingCart.deliveryDatesAvailable),
    orderId: state.shoppingCart.orderId,
    paymentLink: state.shoppingCart.paymentLink
});

const mapDispatchToProps = dispatch => ({
    removeAllProductsFromShoppingCart: () => dispatch(removeAllProductsFromShoppingCart()),
    confirmPickUpLocation: (value, isVip) => dispatch(confirmPickUpLocation(value, isVip)),
    confirmDeliveryLocation: (value, isVip) => dispatch(confirmDeliveryLocation(value, isVip)),
    onSubmitOrder: (payload) => dispatch(submitOrder(payload)),
    onSubmitOrderAndGoToDetails: (payload) => dispatch(submitOrderAndGoToDetails(payload)),
    OnGetAddresses: (token) => dispatch(getAddresses(token)),
    confirmWithoutAddress: (addressType) => dispatch(confirmWithoutAddress(addressType)),
    OnAuthModalToggle: () => dispatch(authModalToggle()),
});


export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);